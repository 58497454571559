import { Controller } from '@hotwired/stimulus';

// import Quagga from 'quagga'; // ES6
import Quagga from '@ericblade/quagga2';

import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = ['barcodePicker', 'pickerOverlay', 'overlayText', 'overlayLoader', 'error', 'input']
  connect() {
    super.connect();

    Quagga.onDetected((data) => {
      const { code } = data.codeResult;

      if (this.isValidLuhn(code)) {
        this.inputTarget.value = code;
      } else {
        Swal.fire({
          title: 'Error',
          text: `El codigo leido no es correcto ${code}`,
          icon: 'error',
          confirmButtonText: 'Cerrar',
        });
      }
      Quagga.stop(); // Detener el escaneo una vez detectado

      this.pickerOverlayTarget.classList.remove('hidden');
    });
  }

  start() {
    this.initScan();
  }

  isValidLuhn(number) {
    const digits = number.split('').reverse();
    let sum = 0;

    for (let i = 0; i < digits.length; i++) {
      let digit = parseInt(digits[i], 10);

      if (i % 2 !== 0) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }

      sum += digit;
    }

    return sum % 10 === 0;
  }

  initScan() {
    Quagga.init({
      inputStream: {
        name: 'Live',
        type: 'LiveStream',
        constraints: {
          facingMode: 'environment', // Usar la cámara trasera
        },
        target: this.barcodePickerTarget, // Or '#yourElement' (optional)
      },
      // 'ean_reader', 'ean_8_reader', 'code_39_reader', 'code_39_vin_reader', 'codabar_reader', 'upc_reader', 'upc_e_reader', 'i2of5_reader'
      decoder: {
        readers: ['code_128_reader'], // Tipos de códigos de barras
      },
    }, (err) => {
      if (err) {
        console.log(err);
        return;
      }

      this.pickerOverlayTarget.classList.add('hidden');

      Quagga.start();
    });
  }

  // static targets = ['barcodePicker', 'pickerOverlay', 'overlayText', 'overlayLoader', 'error']
  // static values = {
  //   configurationError: String,
  // }
  //
  // disconnect() {
  //   if (this.barcodePicker !== undefined
  //       && this.barcodePicker.getState() !== window.Html5QrcodeScannerState.NOT_STARTED) {
  //     this.barcodePicker.stop();
  //   }
  // }
  //
  // async start(event) {
  //   if (this.barcodePicker === undefined) {
  //     this.barcodePicker = new window.Html5Qrcode(this.barcodePickerTarget.id);
  //   }
  //
  //   const scanButton = event.target;
  //   const textField = scanButton.parentNode.querySelector('input[type=text]');
  //
  //   scanButton.setAttribute('disabled', 'disabled');
  //   this.errorTarget.classList.add('hidden');
  //   this.overlayTextTarget.classList.add('hidden');
  //   this.overlayLoaderTarget.classList.remove('hidden');
  //
  //   if (this.barcodePicker.getState() === window.Html5QrcodeScannerState.NOT_STARTED) {
  //     const cameraConfig = { facingMode: 'environment' };
  //     const pickerConfig = {
  //       fps: 100,
  //       qrbox: { width: 200, height: 50 },
  //       formatsToSupport: [window.Html5QrcodeSupportedFormats.CODE_128],
  //       experimentalFeatures: { useBarCodeDetectorIfSupported: true },
  //       rememberLastUsedCamera: true,
  //     };
  //
  //     const onScanSuccess = (decodedText) => {
  //       textField.value = decodedText;
  //       this.pauseBarcodePicker(scanButton);
  //     };
  //
  //     try {
  //       await this.barcodePicker.start(cameraConfig, pickerConfig, onScanSuccess);
  //
  //       this.hidePickerOverlay();
  //     } catch (error) {
  //       this.overlayLoaderTarget.classList.add('hidden');
  //       this.overlayTextTarget.classList.remove('hidden');
  //       this.showError(this.configurationErrorValue);
  //       scanButton.removeAttribute('disabled');
  //     }
  //   } else {
  //     this.barcodePicker.resume();
  //     this.hidePickerOverlay();
  //   }
  // }
  //
  // hidePickerOverlay() {
  //   this.pickerOverlayTarget.classList.add('hidden');
  //   this.overlayLoaderTarget.classList.add('hidden');
  //   this.overlayTextTarget.classList.remove('hidden');
  // }
  //
  // pauseBarcodePicker(resumeButton) {
  //   this.barcodePicker.pause({ shouldPauseVideo: true });
  //   this.pickerOverlayTarget.classList.remove('hidden');
  //   resumeButton.removeAttribute('disabled');
  // }
  //
  // showError(message) {
  //   this.errorTarget.querySelector('p').textContent = message;
  //   this.errorTarget.classList.remove('hidden');
  // }
}
