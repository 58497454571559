import {Controller} from '@hotwired/stimulus';

import Rails from '@rails/ujs';

export default class extends Controller {
    static targets = [
        'input'
    ];

    static values= {
      apiSearch: String,
      enableCache: Boolean,
      searchProperties: String,
      outputResultTemplateTitle: String,
      outputResultTemplateSubtitle: String,
    }

    connect() {
        this.dbDiv = document.createElement("div");
        this.dbDiv.classList.add("hidden");

        this.resultDiv = document.createElement("div");
        this.resultDiv.style.marginTop = "-0.5rem";
        // style="z-index: 100 !important; max-height: 25rem; overflow: auto;"
        this.resultDiv.style.zIndex = "100";
        this.resultDiv.style.maxHeight = "25rem";
        this.resultDiv.style.overflow = "auto";

        this.resultDiv.classList.add("hidden", "mt-1", "border", "bg-gray-200",
            "border-gray-300", "rounded-md", "absolute", "block", "p-2");

        document.addEventListener('click', (_) => {
            if (!this.resultDiv.contains(event.target) && document.activeElement !== this.inputTarget) {
                this.hiddeResultDiv();
            }
        });

        this.inputTarget.addEventListener('focus', () => {
            if(this.resultDiv.children.length > 0) {
                this.showResultDiv();
            }
        });

        this.inputTarget.addEventListener('input', this.onSearchChange.bind(this))

        this.element.appendChild(this.resultDiv);
        this.element.appendChild(this.dbDiv);

        // this.requestData().then((elements) => {
        //     if(elements.length === 1){
        //         this.notifySelectedTarget(elements[0])
        //     }
        // });
    }

    showResultDiv() {
        this.resultDiv.classList.remove("hidden")
    }

    hiddeResultDiv() {
        this.resultDiv.classList.add("hidden")
    }


    notifySelectedTarget(target) {
        this.dispatch("notifySelected", {detail: {content: target}})
        this.hiddeResultDiv();
    }

    onSearchChange() {
        const searchText = this.inputTarget.value;

        if(!searchText) {
            this.hiddeResultDiv();
        }else{
            this.showResultDiv();
        }

        this.resultDiv.innerHTML = "";

        if(searchText && searchText.length > 2) {
            const searchIndicator = document.createElement("label")
            searchIndicator.classList.add("text-gray-400")

            searchIndicator.innerHTML = "Buscando ...";

            this.resultDiv.appendChild(searchIndicator);

            this.requestData(searchText).then((addressDb) => {
                addressDb.forEach(address => {
                    const matchElement = this.buildSearchAddressElement(address, searchText)

                    if(matchElement) {
                        this.resultDiv.appendChild(matchElement);
                    }
                })

                this.resultDiv.removeChild(this.resultDiv.children[0]);

                if(this.resultDiv.children.length === 0) {
                    searchIndicator.innerHTML = "No existen coincidencias";
                    this.resultDiv.appendChild(searchIndicator);
                }
            });
        }else{
            const searchIndicator = document.createElement("label")
            searchIndicator.classList.add("text-gray-400")
            searchIndicator.innerHTML = "Al menos 3 letras para buscar ..."

            this.resultDiv.appendChild(searchIndicator);
        }
    }

    requestData(search_key) {
        return new Promise((resolve, reject) => {
            const rows = this.dbDiv.children;
            if(this.enableCacheValue && rows.length > 0) {
                return resolve(Array.from(rows).map(r => r.dataset));
            }else{
                return Rails.ajax({
                    url: `${this.apiSearchValue}?all=true&q[client_rut_cont]=${encodeURIComponent(search_key)}`,
                    type: 'GET',
                    success: (data) => {
                        if (data) {
                            this.dbDiv.innerHTML = "";

                            if(this.enableCacheValue) {
                                data.forEach((address) => {
                                    const addressDiv = document.createElement("div");
                                    addressDiv.classList.add("hidden");

                                    for (let attribute in address) {
                                        const value = address[attribute];
                                        addressDiv.setAttribute("" + `data-${attribute}`, value)
                                    }

                                    this.dbDiv.appendChild(addressDiv);
                                })
                            }

                            resolve(data);
                        }else{
                            this.resultDiv.innerHTML = "";
                            resolve([]);
                        }
                    },
                    error: (_) => {
                        this.resultDiv.innerHTML = "Ups, un inesperado intente nuevamente...."
                    }
                });
            }
        })
    }

    buildSearchAddressElement(searchObject, searchText) {
        const divRootTemplate = this.element.getElementsByClassName("search-item-template")

        if(!divRootTemplate) {
            console.error("Missing search template");
            return;
        }

        const divRoot = divRootTemplate[0].cloneNode(true);

        divRoot.classList.remove("search-item-template")
        divRoot.classList.remove("hidden")

        divRoot.onclick = this.notifySelectedTarget.bind(this, searchObject);

        const properties = this.searchPropertiesValue.split(",").map(prop => prop.trim());

        let addressLineTitle = this.outputResultTemplateTitleValue.replace(`%${properties[0]}`, searchObject[properties[0]])

        for (let i = 1; i < properties.length; i++) {
            addressLineTitle = addressLineTitle.replaceAll(`%${properties[i]}`, searchObject[properties[i]])
        }

        let addressLineSubtitle = "";

        if(this.hasOutputResultTemplateSubtitleValue) {
            addressLineSubtitle = this.outputResultTemplateSubtitleValue.replace(`%${properties[0]}`, searchObject[properties[0]])
            for (let i = 1; i < properties.length; i++) {
                addressLineSubtitle = addressLineSubtitle.replaceAll(`%${properties[i]}`, searchObject[properties[i]])
            }
        }

        const semanticWords = searchText.split(' ').filter(w => w.length).map(w => w.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''));

        const titleLineRef = addressLineTitle.toLowerCase().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        const globalInterceptions = this.searchGlobalIntersections(semanticWords, titleLineRef);

        let globalInterceptionsSubtitle = [];
        if(this.hasOutputResultTemplateSubtitleValue) {
            const subtitleLineRef = addressLineSubtitle.toLowerCase().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            globalInterceptionsSubtitle = this.searchGlobalIntersections(semanticWords, subtitleLineRef);
        }

        if(globalInterceptions.length + globalInterceptionsSubtitle.length >= semanticWords.length) {

            const newTitleText = this.generateFormattedAddress(addressLineTitle, globalInterceptions);

            let newSubtitleText;
            if(this.hasOutputResultTemplateSubtitleValue) {
                newSubtitleText = this.generateFormattedAddress(addressLineSubtitle, globalInterceptionsSubtitle);

                const outSubtitleDiv = divRoot.getElementsByClassName("result-subtitle-output")[0];

                if(outSubtitleDiv) {
                    outSubtitleDiv.appendChild(newSubtitleText);
                }else{
                    console.warn("No subtitle element defined")
                }
            }

            const outTitleDiv = divRoot.getElementsByClassName("result-title-output")[0];

            if(!outTitleDiv) {
                console.error("Missing result area template");

                return null;
            }else{
                outTitleDiv.appendChild(newTitleText);

                return divRoot;
            }

        }

        return null;

    }

    generateFormattedAddress(addressLineTitle, globalInterceptions) {
        const arrMark = this.markInterceptions(addressLineTitle, globalInterceptions);

        let resultText = this.formatAddressLine(arrMark, addressLineTitle);

        const newText = document.createElement('span');
        newText.innerHTML = resultText;

        return newText;
    }

    formatAddressLine(arrMark, addressLine) {
        let resultText = "";
        let enabled = false;

        for (let j = 0; j < arrMark.length; j++) {
            if (arrMark[j] === 1 && !enabled) {
                enabled = true;
                resultText += `<span class="text-green-700 font-bold">`;
            } else if (arrMark[j] === 0 && enabled) {
                enabled = false;
                resultText += `</span>`;
            }

            resultText += addressLine[j];
        }
        return resultText;
    }

    markInterceptions(addressLine, globalInterceptions) {
        const arrMark = new Array(addressLine.length);
        arrMark.fill(0);

        let i = 0;
        while (i < globalInterceptions.length) {
            const interception = globalInterceptions[i];

            for (let j = 0; j < interception.length; ++j) {
                for (let k = interception[j][0]; k <= interception[j][1]; ++k) {
                    arrMark[k] = 1;
                }
            }
            i++;
        }
        return arrMark;
    }

    searchGlobalIntersections(semanticWords, lineRef) {
        const globalInterceptions = [];

        semanticWords.forEach((word) => {

            let index = 0;
            let interceptions = [];
            let position = lineRef.substring(index).indexOf(word)

            while (position !== -1) {
                interceptions.push([index + position, index + position + word.length - 1])

                if (lineRef.length - (index + position + word.length) > word.length) {
                    index += position + word.length;
                    position = lineRef.substring(index).indexOf(word)
                } else {
                    position = -1;
                }
            }

            if (interceptions.length) {
                globalInterceptions.push(interceptions);
            }
        });
        return globalInterceptions;
    }
}
