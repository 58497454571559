import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dummy', 'result', 'lines']
  static values = {
    form: String,
    search: String,
  }

  initialize() {
    super.initialize();

    this.orderMatch = /(\[[0-9]+\])/gsi;
    // 0 index
    this.actionColumn = 2;

    // this.dummyTarget.addEventListener('blur', this.onSearchBlur.bind(this));
    this.dummyTarget.addEventListener('focus', this.onSearchFocus.bind(this));

    document.addEventListener('mouseup', (e) => {
      if (!this.resultTarget.contains(e.target) && !this.dummyTarget.contains(e.target)) {
        this.hiddeResult();
      }
    });
  }

  // onSearchBlur() {
  //
  // }

  onSearchFocus() {
    this.showResult();
  }

  hiddeResult() {
    this.resultTarget.classList.add('hidden');
  }

  showResult() {
    this.resultTarget.classList.remove('hidden');
  }

  clicked(element) {
    let modal = document.getElementById('order_line_add_modal');
    modal.classList.toggle('hidden');

    let productName = element.target.closest('[data-sku]').dataset.description;

    modal.querySelector('#product-name').innerHTML = productName;

    modal.querySelector('#cancel').onclick = () => {
      modal.classList.toggle('hidden');
    };

    modal.querySelector('#action').onclick = () => {
      this.addElement(element);
      modal.classList.toggle('hidden');
    };
  }

  addElement(element) {
    let skuElement = element.target.closest('[data-sku]');

    this.resultTarget.classList.add('hidden');

    let previousSelected = false;

    for (let i = 0; i < this.linesTarget.children.length; i += 1) {
      if (this.linesTarget.children[i].tagName === 'TR') {
        const currentRow = this.linesTarget.children[i];

        const inputSku = currentRow.querySelector('input');
        if (inputSku.value === skuElement.dataset.sku) {
          previousSelected = true;

          if (currentRow.style.display === 'none') {
            currentRow.removeAttribute('style');
            // Disable removing row
            currentRow.querySelectorAll('td')[this.actionColumn].children[0].value = false;

            return;
          }
        }
      }
    }

    if (!previousSelected) {
      if (skuElement.dataset.sku) {
        let elementRow = this.linesTarget.children[0];

        if (elementRow.querySelector('input').value) {
          elementRow = this.linesTarget.children[0].cloneNode(true);
          elementRow.removeAttribute('style');

          this.linesTarget.appendChild(elementRow);
        }

        const inputs = elementRow.querySelectorAll('input');

        const productLabel = elementRow.querySelector('span.product-label');
        const productSku = elementRow.querySelector('span.product-sku');
        const productAmount = elementRow.querySelector('span.product-amount');

        productLabel.innerHTML = skuElement.dataset.description;
        productSku.innerHTML = skuElement.dataset.sku;
        productAmount.innerHTML = skuElement.dataset.amount;

        const skuInput = inputs[0];
        const amountInput = inputs[1];
        const destroyInput = inputs[2];

        if (this.linesTarget.children.length > 1) {
          const rowsCount = this.linesTarget.querySelectorAll('tr').length - 1;
          const numberChunk = skuInput.name.match(this.orderMatch)[0];

          skuInput.name = skuInput.name.replace(numberChunk, `[${rowsCount}]`);
          amountInput.name = amountInput.name.replace(numberChunk, `[${rowsCount}]`);
          destroyInput.name = destroyInput.name.replace(numberChunk, `[${rowsCount}]`);
        }

        skuInput.value = skuElement.dataset.sku;

        amountInput.value = 1;
        amountInput.min = 1;
        amountInput.max = skuElement.dataset.amount;
      }
    }
  }

  updateInputIndex() {
    const rows = this.linesTarget.querySelectorAll('tr');

    for (let i = 0; i < rows.length; i += 1) {
      if (!rows[i].nextElementSibling || rows[i].nextElementSibling.tagName === 'TR') {
        const rowInputs = rows[i].querySelectorAll('input');

        for (let j = 0; j < rowInputs.length; j += 1) {
          const inputTarget = rowInputs[j];
          const numberChunk = inputTarget.name.match(this.orderMatch)[0];

          inputTarget.name = inputTarget.name.replace(numberChunk, `[${i}]`);
        }
      }
    }
  }

  delete(element) {
    let modal = document.getElementById('order_line_delete_modal');
    modal.classList.toggle('hidden');

    let productName = element.target.closest('tr').querySelector('.product-label').innerHTML;

    modal.querySelector('#product-name').innerHTML = productName;
    // modal.querySelector('#modal-body')
    modal.querySelector('#cancel').onclick = () => {
      modal.classList.toggle('hidden');
    };

    modal.querySelector('#action').onclick = () => {
      this.deleteElement(element);
      modal.classList.toggle('hidden');
    };
  }

  deleteElement(element) {
    const rowTarget = element.target.closest('tr');
    const tbody = rowTarget.closest('tbody');
    const colTarget = element.target.closest('td');

    // const isNewRow = colTarget.children.length === 2;
    const isNewRow = rowTarget.nextElementSibling === null || rowTarget.nextElementSibling.tagName === 'TR';

    if (isNewRow) {
      if (tbody.children.length > 1) {
        rowTarget.remove();

        this.updateInputIndex();
      } else {
        this.clearInputs(rowTarget);
      }
    } else {
      colTarget.children[0].value = true;
      rowTarget.style.display = 'none';
    }
  }

  clearInputs(rowTarget) {
    const inputs = rowTarget.querySelectorAll('input');

    for (let i = 0; i < inputs.length; i += 1) {
      const matches = inputs[i].name.match(this.orderMatch);

      inputs[i].name = inputs[i].name.replace(matches[0], '[0]');
      inputs[i].value = '';
    }

    const infos = rowTarget.querySelectorAll('span');

    for (let i = 0; i < infos.length; i += 1) {
      infos[i].innerHTML = '';
    }
  }

  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.sendRequest.bind(this), 500);
  }

  sendRequest() {
    const inputPath = `#${this.formValue} input[type='search']`;
    const searchInput = document.querySelector(inputPath);

    searchInput.value = this.dummyTarget.value;

    document.getElementById(this.formValue).requestSubmit();
    this.showResult();
  }
}
