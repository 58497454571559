import { Controller } from '@hotwired/stimulus';
import * as Docxtemplater from 'docxtemplater';
import * as JSZip from 'pizzip';
// import tinymce from 'tinymce/tinymce.min';

export default class extends Controller {
  static targets = [
    'fullScreenSelect',
    'fullScreenSelectModal',
    'mainBody',
    'fullScreenModal',
    'dateInit',
    'dateEnd',
    'loadingModal',
    'mainSearchInput',
    'mainTabs',
    'messageModal',
    'logoutModal',
  ];

  static values = {
    selectedFolder: String,
    folderInfoPath: String,
    redirectPath: String,
  };

  connect() {
    // this.fetchOptions(this.folderInfoPathValue);

    const maxDate = new Date().toISOString().split('T')[0];
    this.dateInitTarget.max = maxDate;
    this.dateInitTarget.value = maxDate;
    this.dateEndTarget.max = maxDate;
    this.dateEndTarget.value = maxDate;

    // fetch('/docx.docx')
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     // Pasar el objeto blob a la función para editar el documento
    //     this.editarDocumento(blob);
    //   });

    // this.editDoc();
    this.updateTabInfo();

    this.fullScreenSelectModalTarget.style.visibility = !this.selectedFolderValue ? 'visible' : 'hidden';

    // this.element.addEventListener('app-controller-event', this.handleCustomEvent.bind(this));

    this.mainSearchInputTarget.addEventListener('input', (event) => {
      // e.preventDefault();
      const { value } = event.target;

      this.updateFilterRows(value);
    });
  }

  updateFilterRows(value) {
    const rows = this.mainBodyTarget.querySelectorAll('.contract-row');

    if (rows) {
      rows.forEach((row) => {
        const docName = row.querySelector('.document-name');

        if (!docName.innerHTML.includes(value)) {
          row.classList.add('hidden');
        } else {
          row.classList.remove('hidden');
        }
      });
    }
  }

  // handleCustomEvent(event) {
  //   const eventData = event.detail;
  //   // Realiza alguna acción en ControllerB utilizando los datos recibidos
  //   // console.log(eventData.message);
  // }

  search() {
    const selectedTab = this.mainTabsTarget.querySelector('.selected');

    this.updateTabInfo(selectedTab.getAttribute('data-tab'));
  }

  updateTabInfo(tab) {
    let tabPath = '/mobile/sign/home';
    const maxDate = new Date().toISOString().split('T')[0];

    this.mainSearchInputTarget.value = '';

    switch (tab) {
      case 'signed':
        tabPath += '/partial_document_signed_list';
        break;
      case 'draft':
        tabPath += '/partial_document_draft_list';
        break;
      default:
        tabPath += '/partial_document_list';
    }

    const params = new URLSearchParams();

    params.append('selected_folder', this.selectedFolderValue);
    params.append('date_init', this.dateInitTarget.value || maxDate);
    params.append('date_end', this.dateEndTarget.value || maxDate);

    this.showLoading();

    fetch(`${tabPath}?${params}`)
      .then((response) => response.text())
      .then((data) => {
        this.mainBodyTarget.innerHTML = data;
      })
      // .catch((error) => console.error(error))
      .finally(() => this.hiddeLoading());
  }

  renderNewDocument(event) {
    const url = event.currentTarget.getAttribute('data-url');

    fetch(url)
      .then((response) => response.text())
      .then((data) => {
        this.fullScreenModalTarget.innerHTML = data;
        this.fullScreenModalTarget.style.visibility = 'visible';
      });
    // .catch((error) => console.error(error));
  }

  renderSignedDocument(event) {
    const url = event.currentTarget.getAttribute('data-render-document-url');
    const id = event.currentTarget.getAttribute('data-render-document-id');

    fetch(`${url}?id=${id}`)
      .then((response) => response.text())
      .then((data) => {
        this.fullScreenModalTarget.innerHTML = data;
        this.fullScreenModalTarget.style.visibility = 'visible';
      });
    // .catch((error) => console.error(error));
  }

  closeModal() {
    this.fullScreenModalTarget.innerHTML = '';
    this.fullScreenModalTarget.style.visibility = 'hidden';
  }

  showLoading() {
    this.loadingModalTarget.classList.remove('hidden');
    this.loadingModalTarget.classList.add('show');
  }

  showLogoutModal() {
    this.logoutModalTarget.classList.remove('hidden');
    this.logoutModalTarget.classList.add('show');
  }

  hiddeLogoutModal() {
    this.logoutModalTarget.classList.remove('show');
    this.logoutModalTarget.classList.add('hidden');
  }

  hiddeLoading() {
    this.loadingModalTarget.classList.remove('show');
    this.loadingModalTarget.classList.add('hidden');
  }

  hiddeMessageModal() {
    this.messageModalTarget.classList.remove('show');
    this.messageModalTarget.classList.add('hidden', 'fade');
  }

  changeTab(event) {
    const tab = event.currentTarget.getAttribute('data-tab');

    const elements = event.currentTarget.parentElement.getElementsByTagName('a');

    for (let i = 0; i < elements.length; i += 1) {
      elements[i].classList.remove('border-b-4', 'selected');
    }

    event.currentTarget.classList.add('border-b-4', 'selected');

    this.updateTabInfo(tab);
  }

  goToIndex(folderName) {
    // Construye la URL con los parámetros
    const url = `${this.redirectPathValue}?selecting_folder=true&selected_folder=${folderName}`;

    // Redirige a la URL
    window.location.href = url;
  }

  // async fetchOptions() {
  //   // try {
  //   //   const response = await fetch('/mobile/sign/home/folder_info', {
  //   //     method: 'GET', // O 'POST' si necesitas enviar datos al servidor
  //   //     headers: {
  //   //       'Content-Type': 'application/json', // Especifica que esperas una respuesta JSON
  //   //     },
  //   //   })
  //   //     .then((data) => data.json()); // Reemplaza "/ruta/datos" con tu ruta de servidor
  //   //
  //   //   response.forEach((option) => {
  //   //     const selectOption = document.createElement('option');
  //   //     selectOption.text = option;
  //   //     selectOption.value = option;
  //   //     this.fullScreenSelectTarget.appendChild(selectOption);
  //   //   });
  //   // } catch (error) {
  //   //   console.error('Error al obtener opciones:', error);
  //   // }
  // }

  handleSelectedFolderChange(event) {
    // console.log('Opción seleccionada:', event.target.value);
    this.goToIndex(event.target.value);
  }

  editarDocumento(blob) {
    const reader = new FileReader();
    reader.onload = function () {
      const arrayBuffer = this.result;
      const buffer = new Uint8Array(arrayBuffer);

      const doc = new Docxtemplater();
      doc.loadZip(new JSZip(buffer));

      // Realiza cualquier manipulación o relleno de datos necesarios en el documento
      // doc.setData(...);
      // doc.render();

      const documentoEditado = doc.getZip().generate({ type: 'blob' });

      // Descargar el documento editado
      const url = URL.createObjectURL(documentoEditado);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'documento_editado.docx';
      link.click();
      URL.revokeObjectURL(url);
    };
    reader.readAsArrayBuffer(blob);
  }
}
