import { Controller } from '@hotwired/stimulus';

import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'postOfficesInput',
    'postOfficesArea',
    'postOfficesResult',
    'deliveryTypeSelect',
    'postOfficeField',
    // 'requiredDocument',
    //
    // 'phoneLineDocument',
    // 'debitDocument',
    // 'agreementDocument',
    // 'invoiceDocument',
  ]

  static values = {
    apiSearch: String,
  }

  updateOnPostOfficeChanged(checked, clearFields = false) {
    // this.phoneLineDocumentTarget.value = null;
    // this.debitDocumentTarget.value = null;
    // this.agreementDocumentTarget.value = null;
    // this.invoiceDocumentTarget.value = null;

    if (checked) {
      this.postOfficesAreaTarget.classList.remove('hidden');
      // this.requiredDocumentTargets.forEach((element, index) => {
      //   element.disabled = true;
      //   element.checked = index === 0;
      // });

      // this.phoneLineDocumentTarget.disabled = true;
      // this.debitDocumentTarget.disabled = true;
      // this.agreementDocumentTarget.disabled = true;

      const event = new CustomEvent('showCustomAddress', {
        detail: {
          clean: clearFields,
        },
      });

      document.dispatchEvent(event);
    } else {
      this.postOfficesAreaTarget.classList.add('hidden');

      // this.requiredDocumentTargets.forEach((element) => {
      //   // eslint-disable-next-line no-param-reassign
      //   element.disabled = false;
      //   // eslint-disable-next-line no-param-reassign
      //   element.checked = false;
      // });

      // this.phoneLineDocumentTarget.disabled = false;
      // this.debitDocumentTarget.disabled = false;
      // this.agreementDocumentTarget.disabled = false;

      const event = new CustomEvent('clearAndRestoreMap');

      document.dispatchEvent(event);
    }
  }

  connect() {
    this.postOfficesInputTarget.addEventListener('input', this.onPostOfficeInputChanged.bind(this));
    this.postOfficesInputTarget.addEventListener('focus', this.onPostOfficeInputFocus.bind(this));
    this.postOfficesInputTarget.addEventListener('blur', this.onPostOfficeInputBlur.bind(this));

    if (this.deliveryTypeSelectTarget.value === 'post_office') {
      this.updateOnPostOfficeChanged(true);
    } else {
      this.postOfficesAreaTarget.classList.add('hidden');
    }

    this.deliveryTypeSelectTarget.addEventListener('change', () => {
      if (this.deliveryTypeSelectTarget.value === 'post_office') {
        this.updateOnPostOfficeChanged(true, true);
      } else {
        this.updateOnPostOfficeChanged(false);
      }
    });

    // document.onclick = (e) => {
    //   if (e.target !== this.postOfficesInputTarget) {
    //     // element clicked wasn't the div; hide the div
    //     if (!this.postOfficesResultTarget.classList.contains('hidden')) {
    //       this.postOfficesResultTarget.classList.add('hidden');
    //     }
    //   }
    // };
  }

  onPostOfficeInputFocus() {
    this.postOfficesResultTarget.classList.remove('hidden');
  }

  // eslint-disable-next-line class-methods-use-this
  onPostOfficeInputBlur() {
    // this.postOfficesResultTarget.classList.add('hidden');
  }

  onPostOfficeElementClicked(office) {
    this.postOfficesResultTarget.classList.add('hidden');

    this.postOfficeFieldTarget.value = office.id;

    const event = new CustomEvent('showCustomAddress', {
      detail: {
        office: { ...office, ovservation: office.schedules }, readOnly: true,
      },
    });

    document.dispatchEvent(event);

    this.postOfficesInputTarget.value = office.name;
  }

  buildSearchElement(office) {
    let divRoot = document.createElement('div');

    divRoot.onclick = this.onPostOfficeElementClicked.bind(this, office);

    let h3 = document.createElement('h3');
    let h3Locality = document.createElement('h3');

    divRoot.classList.add('p-2', 'cursor-pointer', 'hover:border', 'rounded-md', 'hover:bg-gray-100');

    h3.classList.add('text-md', 'font-bold');
    h3.innerHTML = office.name;

    h3Locality.classList.add('text-xs', 'font-bold', 'text-gray-400');
    h3Locality.innerHTML = `${office.address_locality}, ${office.address_state}`;

    divRoot.append(h3);
    divRoot.append(h3Locality);

    return divRoot;
  }

  onFilterSearchResult(data) {
    this.postOfficesResultTarget.innerHTML = '';

    if (!data || data.length === 0) {
      this.postOfficesResultTarget.innerHTML = 'Sin resultados';
    }

    data.forEach((office) => {
      let element = this.buildSearchElement(office);
      this.postOfficesResultTarget.append(element);
    });
  }

  onPostOfficeInputChanged() {
    const { value } = this.postOfficesInputTarget;

    if (value && value.length > 2) {
      let formData = new FormData();

      formData.append('q[name_cont]', value);

      Rails.ajax({
        type: 'POST',
        accept: 'json',
        dataType: 'json',
        url: this.apiSearchValue,
        data: formData,
        success: this.onFilterSearchResult.bind(this),
      });
    } else {
      this.postOfficesResultTarget.innerHTML = '...';
    }
  }
}
