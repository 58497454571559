import { Controller } from '@hotwired/stimulus';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import SignaturePad from 'signature_pad';
// import * as pdfjs from './pdfjs/build/pdf';
// import * as pdfviewer from './pdfjs/web/pdf_viewer';

// import('./pdfjs/web/pdf_viewer.css');

export default class extends Controller {
  static targets = [
    'clientCanvas',
    'deliveryCanvas',
    'pdfContainer',
    'imagenInput',
    'imagenPreview',
    'signModal',
    'signCanvas',
    'deliverySignInput',
    'clientSignInput',
    'imgPreviewModal',
    'signName',
    'deliveryNameInput',
    'clientNameInput',
    'deliveryNameInfoLbl',
    'clientNameInfoLbl',
    'submitBtn',
    'mainForm',
    'pdfPreviewModal',
    'evidenceImageDeleteList',
    'loadingModal',
    'errorModal',
    'unsavedInfoModal',
    'signedOkModal',
    'signQuestionModal',
    'previewActionBtn',
    'messageModal',
  ];

  static values = {
    selectedFolder: String,
    pdfBinary: String,
    previewPdfUrl: String,
    pdfClientKey: String,
  };

  // ***********************
  // Preview image
  // ***********************
  // function preview() {
  //   frame.src=URL.createObjectURL(event.target.files[0]);
  // }

  // <form>
  // <input type="file" onChange="preview()">
  // <img id="frame" src="" width="100px" height="100px"/>
  // </form>
  // ***********************
  // ***********************

  showModal(event) {
    const source = event.currentTarget.getAttribute('data-source');

    this.signModalTarget.setAttribute('data-source', source);
    this.signModalTarget.classList.remove('hidden');

    if (source === 'app-user') {
      this.signNameTarget.value = this.deliveryNameInputTarget.value;
    } else {
      this.signNameTarget.value = this.clientNameInputTarget.value;
    }

    this.signModalTarget.classList.add('show');
  }

  // eslint-disable-next-line class-methods-use-this
  readAsArrayBuffer(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = (event) => {
        const arrayBuffer = event.target.result; // Aquí tienes el ArrayBuffer
        resolve(arrayBuffer);
      };

      fileReader.onerror = (event) => {
        reject(event.target.error);
      };

      fileReader.readAsArrayBuffer(file);
    });
  }

  closePdfPreviewModal() {
    this.pdfPreviewModalTarget.classList.remove('show');
    this.pdfPreviewModalTarget.classList.add('hidden');
    this.pdfPreviewModalTarget.querySelector('div.preview-pdf-body').innerHTML = '';
  }

  hiddeSignQuestionModal() {
    this.signQuestionModalTarget.classList.remove('show');
    this.signQuestionModalTarget.classList.add('hidden');
  }

  showSignQuestionModal() {
    this.signQuestionModalTarget.classList.remove('hidden');
    this.signQuestionModalTarget.classList.add('show');
  }

  isFormValid() {
    const isClientCanvasValid = this.isCanvasBlank(this.clientCanvasTarget);
    const isMovistarCanvasValid = this.isCanvasBlank(this.deliveryCanvasTarget);

    let inValue = this.deliveryNameInputTarget.value;

    const isDeliveryNameValid = inValue && inValue.trim().length > 0;

    inValue = this.clientNameInputTarget.value;

    const isClientNameValid = inValue && inValue.trim().length > 0;

    return isClientCanvasValid && isMovistarCanvasValid && isDeliveryNameValid && isClientNameValid;
  }

  isFormDirty() {
    // this.initialFormInfo = {
    //   clientCanvasTarget: this.clientCanvasTarget.src || false,
    //   deliveryCanvasTarget: this.deliveryCanvasTarget.src || false,
    //   deliveryNameInputTarget: this.deliveryNameInputTarget.value,
    //   clientNameInputTarget: this.clientNameInputTarget.value,
    // };
    return (this.initialFormInfo.clientCanvasTarget !== this.clientCanvasTarget.src
        || this.initialFormInfo.deliveryCanvasTarget !== this.deliveryCanvasTarget.src
        || this.initialFormInfo.deliveryNameInputTarget !== this.deliveryNameInputTarget.value
        || this.initialFormInfo.clientNameInputTarget !== this.clientNameInputTarget.value
    );
  }

  async showPreview(event) {
    // const pdfDoc = await PDFDocument.load(this.convertDataURIToBinary(this.pdfBinaryValue));
    if (!this.isFormValid()) {
      this.showErrorModal();

      return;
    }

    if (this.isFormDirty()) {
      this.showUnsavedInfoModal();

      return;
    }

    this.showLoading();

    const source = event.currentTarget.getAttribute('data-source');

    fetch(source, {
      method: 'GET',
      headers: {
        Accept: 'application/json', // Indica que aceptamos JSON como respuesta
      },
    }).then((response) => response.blob())
      // .then((data) => data.base64_data)
      .then((pdfBytes) => {
        const previewModalArea = this.pdfPreviewModalTarget.querySelector('div.preview-pdf-body');
        this.pdfPreviewModalTarget.classList.remove('hidden');
        this.pdfPreviewModalTarget.classList.add('show');

        previewModalArea.innerHTML = '';
        const pdfParent = document.createElement('div');
        pdfParent.classList.add('w-full', 'h-full');

        previewModalArea.append(pdfParent);

        // this.pdfPreviewModalTarget.classList.add('fade');

        // const pdfBytes = await pdfDoc.saveAsBase64();

        WebViewer({
          path: '/public', // point to where the files you copied are served from
          licenseKey: this.pdfClientKeyValue,
          // initialDoc: 'https://pdftron.s3.amazonaws.com/downloads/pl/PDFTRON_about.pdf', // path to your document
          // initialDoc: pdfBytes, // path to your document
        }, previewModalArea.firstChild).then(async (instance) => {
          instance.UI.loadDocument(pdfBytes, { filename: 'myfilepreview.pdf' });

          const { documentViewer } = instance.Core;
          documentViewer.addEventListener('documentLoaded', () => {
            // perform document operations
            this.hiddeLoading();
          });
          // Call APIs here
        });
      });
  }

  saveDocument() {
    this.submitBtnTarget.click();
  }

  removeFileByName(fileList, fileName) {
    const filesArray = Array.from(fileList);
    const filteredFiles = filesArray.filter((file) => file.name !== fileName);

    // Crear una nueva FileList a partir del array filtrado
    const newFileList = new DataTransfer();
    filteredFiles.forEach((file) => newFileList.items.add(file));

    return newFileList.files;
  }

  deleteImage() {
    const imageId = this.imgPreviewModalTarget.getElementsByTagName('img')[0].id;

    // eslint-disable-next-line no-restricted-globals
    if (imageId && !isNaN(imageId)) {
      const deletedImageInput = document.createElement('input');

      deletedImageInput.type = 'number';
      deletedImageInput.style.display = 'none';
      deletedImageInput.name = 'deleted_image_ids[]';
      deletedImageInput.value = imageId;

      this.evidenceImageDeleteListTarget.append(deletedImageInput);
    } else {
      this.imagenInputTarget.files = this.removeFileByName(this.imagenInputTarget.files, imageId);
    }

    this.imagenPreviewTarget.querySelector(`img[id="${imageId}"]`).remove();

    this.closeImagePreview();
  }

  setSign() {
    const source = this.signModalTarget.getAttribute('data-source');

    const canvas = this.signCanvasTarget;
    let imageInput = this.clientSignInputTarget;

    const signerName = this.signNameTarget.value;

    if (source === 'app-user') {
      imageInput = this.deliverySignInputTarget;

      this.deliveryNameInputTarget.value = signerName;
      this.deliveryNameInfoLblTarget.innerHTML = signerName;
    } else {
      this.clientNameInputTarget.value = signerName;
      this.clientNameInfoLblTarget.innerHTML = signerName;
    }

    if (canvas.getContext('2d')
      .getImageData(0, 0, canvas.width, canvas.height).data
      .some((channel) => channel !== 0)) {
      canvas.toBlob((blob) => {
        // Crear un objeto File a partir del blob
        const url = URL.createObjectURL(blob);
        // Asignar el archivo al input tipo file
        imageInput.src = url;

        const imageFile = new File([blob], source, { type: 'image/png' });

        let container = new DataTransfer();
        container.items.add(imageFile);

        if (source === 'app-user') {
          this.deliveryCanvasTarget.src = url;

          this.deliverySignInputTarget.files = container.files;
        } else {
          this.clientCanvasTarget.src = url;

          this.clientSignInputTarget.files = container.files;
        }
      }, 'image/png');
    }

    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    this.hideModal();
  }

  hideModal() {
    this.signModalTarget.classList.remove('show');
    this.signModalTarget.classList.add('hidden');
    this.signModalTarget.classList.add('fade');
  }

  hiddeSignedOkModal() {
    this.signedOkModalTarget.classList.remove('show');
    this.signedOkModalTarget.classList.add('hidden');
    this.signedOkModalTarget.classList.add('fade');

    window.location.reload();
  }

  showSignedOkModal() {
    this.signedOkModalTarget.classList.add('show');
    this.signedOkModalTarget.classList.remove('hidden');
    this.signedOkModalTarget.classList.remove('fade');
  }

  loadSignCanvas() {
    const canvas = this.signCanvasTarget;
    const signaturePad = new SignaturePad(canvas);
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'application/pdf' });
  }

  showLoading() {
    this.loadingModalTarget.classList.remove('hidden');
    this.loadingModalTarget.classList.add('show');
  }

  hiddeLoading() {
    this.loadingModalTarget.classList.remove('show');
    this.loadingModalTarget.classList.add('hidden');
  }

  showErrorModal() {
    this.errorModalTarget.classList.remove('hidden');
    this.errorModalTarget.classList.add('show');
  }

  hiddeErrorModal() {
    this.errorModalTarget.classList.remove('show');
    this.errorModalTarget.classList.add('hidden');
  }

  showUnsavedInfoModal() {
    this.unsavedInfoModalTarget.classList.remove('hidden');
    this.unsavedInfoModalTarget.classList.add('show');
  }

  hiddeUnsavedInfoModalModal() {
    this.unsavedInfoModalTarget.classList.remove('show');
    this.unsavedInfoModalTarget.classList.add('hidden');
  }

  convertDataURIToBinary(dataURI) {
    // const BASE64_MARKER = ';base64, ';
    // const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = dataURI.substring(dataURI);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  base64ToUint8Array(base64) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i += 1) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  onFilterChanged(event) {
    const data = event.detail.filter_key;

    if (data) {
      console.log('Actualizando data', data);
    }
  }

  showSaveOkMEssageModal() {
    this.messageModalTarget.classList.remove('hidden');
    this.messageModalTarget.classList.add('show');
  }

  hiddeSaveOkMEssageModal() {
    this.messageModalTarget.classList.remove('show');
    this.messageModalTarget.classList.add('hidden');
  }

  onFormSubmit() {
    const formData = new FormData(this.mainFormTarget); // Crear un objeto FormData con los datos del formulario, incluyendo la imagen

    const xhr = new XMLHttpRequest(); // Crear una instancia de XMLHttpRequest

    // Configurar la solicitud AJAX
    xhr.open('POST', this.mainFormTarget.getAttribute('action'), true);

    // Configurar un controlador para manejar la respuesta del servidor
    xhr.onload = () => {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        if (response.success) {
          this.showSaveOkMEssageModal();
        }
      } else {
        console.error('Error en la solicitud AJAX');
      }
    };

    // Enviar la solicitud AJAX con los datos del formulario
    xhr.send(formData);
  }

  registerForm() {
    this.mainFormTarget.addEventListener('submit', (e) => {
      e.preventDefault(); // Prevenir el envío del formulario por defecto

      this.onFormSubmit();
    });
  }

  connect() {
    // pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';
    this.currentSelectedImages = [];

    this.initialFormInfo = {
      clientCanvasTarget: this.clientCanvasTarget.src || false,
      deliveryCanvasTarget: this.deliveryCanvasTarget.src || false,
      deliveryNameInputTarget: this.deliveryNameInputTarget.value,
      clientNameInputTarget: this.clientNameInputTarget.value,
    };

    this.generatedStrings = new Set();

    if (!this.isFormValid()) {
      this.previewActionBtnTarget.classList.add('hidden');
    }

    this.registerForm();

    const pdfData = this.pdfBinaryValue;
    // const pdfFile = this.base64ToBlob(pdfData);
    // const pdfFile = this.base64ToUint8Array(pdfData);
    this.loadSignCanvas();

    const images = this.imagenPreviewTarget.getElementsByTagName('img');

    const imgSources = [];

    for (let image of images) {
      image.onclick = () => {
        this.showImagePreview(image.src, image.id);
      };

      imgSources.push(image.src);
    }

    this.initialFormInfo.imageSources = imgSources;

    this.imagenInputTarget.addEventListener('change', () => {
      this.updateImages();
    });

    this.showLoading();

    WebViewer({
      path: '/public', // point to where the files you copied are served from
      licenseKey: this.pdfClientKeyValue,
      // initialDoc: 'https://pdftron.s3.amazonaws.com/downloads/pl/PDFTRON_about.pdf', // path to your document
      // initialDoc: URL.createObjectURL(pdfFile), // path to your document
    }, this.pdfContainerTarget).then((instance) => {
      instance.UI.loadDocument(this.base64ToBlob(pdfData), { filename: 'order_preview.pdf' });

      const { documentViewer } = instance.Core;
      documentViewer.addEventListener('documentLoaded', () => {
        // perform document operations
        this.hiddeLoading();
      });
      // Call APIs here
    });

    this.deliverySignInputTarget.addEventListener('change', () => {
      const file = this.deliverySignInputTarget.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const imageUrl = event.target.result;
        // const imagePreview = document.createElement('img');
        // imagePreview.src = imageUrl;
        this.deliveryCanvasTarget.src = imageUrl;
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        // previewContainer.innerHTML = 'No se seleccionó ninguna imagen';
        console.log('No se seleccionó ninguna imagen');
      }
    });

    this.clientSignInputTarget.addEventListener('change', () => {
      const file = this.clientSignInputTarget.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const imageUrl = event.target.result;
        // const imagePreview = document.createElement('img');
        // imagePreview.src = imageUrl;
        this.userCanvasTarget.src = imageUrl;
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        // previewContainer.innerHTML = 'No se seleccionó ninguna imagen';
        console.log('No se seleccionó ninguna imagen');
      }
    });
  }

  signDocumentAndEmail(event) {
    const docId = event.target.getAttribute('data-doc-id');
    const signUrl = event.target.getAttribute('data-sign-url');
    const csrfToken = event.target.getAttribute('data-form-csrf-value');

    const formData = new FormData();

    formData.append('docId', docId);

    fetch(signUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: formData,
    }).then((data) => data.json())
      .then((jsonInfo) => {
        if (jsonInfo.status && jsonInfo.status === 'ok') {
          console.log('OK SAVED');
        }

        this.showSignedOkModal();
      });
  }

  // eslint-disable-next-line class-methods-use-this
  generateRandomLetters(length) {
    const charset = 'abcdefghijklmnopqrstuvwxyz';
    let randomLetters = '';

    while (randomLetters.length < length) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      const randomLetter = charset.charAt(randomIndex);

      if (!randomLetters.includes(randomLetter)) {
        randomLetters += randomLetter;
      }
    }

    return randomLetters;
  }

  generateUniqueRandomLetters(length) {
    let randomLetters;

    do {
      randomLetters = this.generateRandomLetters(length);
    } while (this.generatedStrings.has(randomLetters));

    this.generatedStrings.add(randomLetters);

    return randomLetters;
  }

  showImagePreview(source, sourceId) {
    const previewImageElement = this.imgPreviewModalTarget.getElementsByTagName('img')[0];
    previewImageElement.src = source;
    previewImageElement.id = sourceId;

    this.imgPreviewModalTarget.classList.remove('hidden');
    this.imgPreviewModalTarget.classList.add('show');
  }

  closeImagePreview() {
    const imageElement = this.imgPreviewModalTarget.getElementsByTagName('img')[0];
    imageElement.id = '';
    imageElement.src = '';

    this.imgPreviewModalTarget.classList.remove('show');
    this.imgPreviewModalTarget.classList.add('hidden');
  }

  updateImages() {
    const { files } = this.imagenInputTarget;

    this.currentSelectedImages.push(...files);

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        // const reader = new FileReader();

        // reader.onload = (e) => {
        const image = document.createElement('img');
        // image.src = e.target.result;
        const source = URL.createObjectURL(files[i]);
        if (!image.id) {
          image.id = files[i].name;
        }
        image.src = source;
        image.onclick = () => {
          this.showImagePreview(source, image.id);
        };
        image.style.maxWidth = '128px';
        image.style.border = '3px solid gray';
        image.style.borderRadius = '3%';

        image.width = 200; // Ajusta el ancho de la miniatura según tus necesidades
        this.imagenPreviewTarget.appendChild(image);
        // };

        // reader.readAsDataURL(files[i]);
      }
    }

    let container = new DataTransfer();
    this.currentSelectedImages.forEach((img) => {
      container.items.add(img);
    });

    this.imagenInputTarget.files = container.files;
  }

  abrirSeleccionador() {
    this.imagenInputTarget.click();
  }

  // returns true if every pixel's uint32 representation is 0 (or "blank")
  isCanvasBlank(canvas) {
    if (!canvas.src) {
      return false;
    }

    const pixelBuffer = new Uint32Array(
      canvas.src,
    );

    return !pixelBuffer.some((color) => color !== 0);
  }
}
