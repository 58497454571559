import { Application } from '@hotwired/stimulus';

// import '@hotwired/turbo-rails';

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

window.addEventListener('load', (event) => {
  document.getElementsByTagName('body')[0].removeAttribute('x-cloak');
});

export { application };
