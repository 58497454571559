import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tab', 'content', 'default']
  static values = { position: Number }

  initialize() {
    super.initialize();

    this.setSelectedTab(this.tabTargets[this.positionValue]);

    this.hideContents();
    this.showContent(this.positionValue);
  }

  showContent(position) {
    this.contentTargets[position].classList.remove('hidden');
  }

  toggleTabs(event) {
    let i = 0;
    let position = 0;
    this.tabTargets.forEach((el) => {
      if (event.target === el) {
        this.setSelectedTab(el);
        position = i;
      } else {
        this.setUnselectedTab(el);
      }
      i += 1;
    });

    this.hideContents();

    this.showContent(position);
  }

  setUnselectedTab = (element) => {
    element.classList.remove('border-b-2');
    element.classList.remove('border-gray-900');
    element.classList.remove('selected');
  }

  setSelectedTab = (element) => {
    element.classList.add('border-b-2');
    element.classList.add('border-gray-900');
    element.classList.add('selected');
  }

  hideContents = () => {
    this.contentTargets.forEach((el) => {
      el.classList.add('hidden');
    });
  }
}
