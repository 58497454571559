import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = [
    'search',
    'map',
    'latLngPrecision',
    'latitude',
    'longitude',
    'address',
    'aptOrSuite',
    'corner',
    'state',
    'stateCopy',
    'locality',
    'localityCopy',
    'latlngbtn',
    'observations',
  ];

  static values = {
    url: String,
  };

  initialize() {
    super.initialize();
    this.globalClickListenerController = true;
  }

  connect() {
    if (typeof (google) !== 'undefined') {
      this.initializeMap();
    }

    this.localityTarget.addEventListener('change', (event) => {
      this.localityCopyTarget.value = event.target.value;

      if(this.localityTarget.hasAttribute('disabled')){
        this.localityCopyTarget.removeAttribute('disabled')
      }else{
        this.localityCopyTarget.disabled = 'disabled'
      }

    })

    this.stateTarget.addEventListener('change', (event) => {
      this.stateCopyTarget.value = event.target.value;
      // this.stateCopyTarget.disabled = !this.stateTarget.disabled
      // this.stateCopyTarget.name = this.stateTarget.name

      if(this.stateTarget.hasAttribute('disabled')){
        this.stateCopyTarget.removeAttribute("disabled")
      }else{
        this.stateCopyTarget.disabled = "disabled"
      }

      this.updateLocality();
    })

    if(this.localityTarget.hasAttribute('disabled')){
      this.localityCopyTarget.removeAttribute('disabled')
    }else{
      this.localityCopyTarget.disabled = 'disabled'
    }

    if(this.stateTarget.hasAttribute('disabled')){
      this.stateCopyTarget.removeAttribute("disabled")
    }else{
      this.stateCopyTarget.disabled = "disabled"
    }

    this.refreshHiddenCopyValues(true);
  }

  initializeMap() {
    this.map();
    this.marker();
    this.geoXml();
    this.autocomplete();

    this.latlngbtnTarget.addEventListener('click', this.byLatLng.bind(this));
  }

  byLatLng(_) {
    if (this.latitudeTarget.value && this.longitudeTarget.value) {
      const latlng = {
        lat: parseFloat(this.latitudeTarget.value),
        lng: parseFloat(this.longitudeTarget.value),
      };

      this.findByLatLng(latlng);
    } else {
      window.alert('Valores no válidos.');
    }
  }

  onMapClicked(event) {
    this.handleMarkerDraggedEvent(event);
  }

  map() {
    if (this.mapObj === undefined) {
      this.mapObj = new google.maps.Map(this.mapTarget, {
        componentRestrictions: { country: 'uy' },
        mapTypeId: 'roadmap',
        center: new google.maps.LatLng(
          this.latitudeTarget.value || -34.90522402474809,
          this.longitudeTarget.value || -56.18421220001321,
        ),
        zoom: 16,
      });
      // On click set marker position
      this.mapObj.addListener('click', this.onMapClicked.bind(this));
      //
      // google.maps.event.addListener(this.mapObj, 'dragend', () => {
      //   console.log('drag');
      // });

      this.disableMapInteraction();
      // By default enable map interactions
      this.globalClickListenerController = true;
    }
    return this.mapObj;
  }

  disableMapInteraction() {
    this.map().setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      disableDoubleClickZoom: true,
    });

    this.marker().draggable = false;

    this.globalClickListenerController = false;
  }

  enableMapInteraction() {
    // On click set marker position

    this.marker().draggable = true;
    this.globalClickListenerController = true;

    this.map().setOptions({
      draggable: true,
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: false,
    });
  }

  geoXml() {
    if (this.geoXmlObj === undefined) {
      this.geoXmlObj = new geoXML3.parser({
        map: this.map(),
        singleInfoWindow: true,
        suppressInfoWindows: true,
        zoom: false,
        // addmarker: (marker, title, description, number, iconImage) => {
        //   console.log(marker, title, description, number, iconImage);
        // },
        // directions: true,
        // markeroptions: {
        //   draggable: true,
        // },
      });

      this.geoXmlObj.parse([
        '/red_zones_2.kml',
        // '/red_zones_3_perossi.kml'
        // '/URY_0.kml'
      ]);
    }

    return this.geoXmlObj;
  }

  capitalizeWords(sentence) {
    return sentence.toLowerCase().split(' ')
        .map(word => {
          if(word.length > 1) {
            return word.charAt(0).toUpperCase() + word.slice(1);
          }else{
            return word;
          }
        })
        .join(' ');
  }

  clearAndEnableInteraction() {
    this.searchTarget.value = '';
    this.observationsTarget.value = '';

    this.clearLocation();
    this.enableMapInteraction();

    this.updateFieldsDisabled(false);
  }

  setAddressRO({ detail }) {
    this.searchTarget.value = '';

    if(detail.clean) {
      this.clearLocation();
    }

    const { office } = detail;
    // eslint-disable-next-line camelcase

    if(office) {
      const {address_state, address_locality} = office;

      this.clearLocation();

      if(office.lat && office.lng && typeof office.lat === "number" && typeof office.lng === "number") {
        const location = {
          lat: parseFloat(office.lat),
          lng: parseFloat(office.lng),
        };

        this.map().setCenter(location);
        this.marker().setPosition(location);
        this.marker().setVisible(true);

        this.latitudeTarget.value = location.lat;
        this.longitudeTarget.value = location.lng;
      }else {
        this.latitudeTarget.value = null;
        this.longitudeTarget.value = null;
      }

      if(office.corner) {
        this.cornerTarget.value = office.corner
      }

      if(office.apto) {
        this.aptOrSuiteTarget.value = office.apto
      }

      this.addressTarget.value = '';

      // eslint-disable-next-line camelcase
      this.stateTarget.value = this.capitalizeWords(address_state);

      this.notifySelectOnChangeEvent(this.stateTarget)

      this.addressTarget.value = office.address;
      this.observationsTarget.value = office.observation;

      if (office.phone) {
        this.observationsTarget.value = `${this.observationsTarget.value} (${office.phone})`;
      }

      const { keepLocality } = detail
      // eslint-disable-next-line camelcase
      let dummyLocality = address_locality;

      if(!keepLocality) {
        dummyLocality = address_state.toLowerCase() === 'montevideo' ? 'Montevideo' : address_locality;
      }

      this.updateLocality(this.capitalizeWords(dummyLocality));
    }

    this.disableMapInteraction();

    this.updateFieldsDisabled(true);
  }

  refreshHiddenCopyValues(shouldDisable) {

    this.stateCopyTarget.name = this.stateTarget.name;
    this.localityCopyTarget.name = this.localityTarget.name;

    // if(!shouldDisable){
    //   this.localityCopyTarget.removeAttribute("disabled")
    //   this.stateCopyTarget.removeAttribute("disabled")
    // }else{
    //   this.stateCopyTarget.disabled = shouldDisable
    //   this.localityCopyTarget.disabled = shouldDisable
    // }
  }

  updateFieldsDisabled(readonly) {
    this.searchTarget.readOnly = readonly;

    this.latitudeTarget.readOnly = readonly;
    this.longitudeTarget.readOnly = readonly;
    this.addressTarget.readOnly = readonly;

    if(readonly) {
      this.stateTarget.disabled = "disabled";
      this.stateCopyTarget.removeAttribute("disabled")
    }else{
      this.stateTarget.removeAttribute("disabled")
      this.stateCopyTarget.disabled = "disabled"
    }

    if(readonly) {
      this.localityTarget.disabled = "disabled";
      this.localityCopyTarget.removeAttribute("disabled")
    }else{
      this.localityTarget.removeAttribute("disabled")
      this.localityCopyTarget.disabled = "disabled"
    }

    // this.refreshHiddenCopyValues(!readonly)

    this.addressTarget.readOnly = readonly;
    this.aptOrSuiteTarget.readOnly = readonly;
    this.cornerTarget.readOnly = readonly;
    this.observationsTarget.readOnly = readonly;
  }

  // === A method for testing if a point is inside a polygon
  // === Returns true if poly contains point
  // === Algorithm shamelessly stolen from http://alienryderflex.com/polygon/
  contains(path, point) {
    let j = 0;
    let oddNodes = false;
    let x = point.lng();
    let y = point.lat();
    for (let i = 0; i < path.getPath().getLength(); i++) {
      j++;
      if (j === path.getPath().getLength()) { j = 0; }
      if (((path.getPath().getAt(i).lat() < y) && (path.getPath().getAt(j).lat() >= y))
        || ((path.getPath().getAt(j).lat() < y) && (path.getPath().getAt(i).lat() >= y))) {
        if (path.getPath().getAt(i).lng() + (y - path.getPath().getAt(i).lat())
          / (path.getPath().getAt(j).lat() - path.getPath().getAt(i).lat())
          * (path.getPath().getAt(j).lng() - path.getPath().getAt(i).lng()) < x) {
          oddNodes = !oddNodes;
        }
      }
    }
    return oddNodes;
  }

  geocoder() {
    if (this.geocoderObj === undefined) {
      this.geocoderObj = new google.maps.Geocoder();
    }

    return this.geocoderObj;
  }

  handleMarkerDraggedEvent(event) {
    if (this.globalClickListenerController) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      const latlng = {
        lat,
        lng,
      };

      this.findByLatLng(latlng);
    }
  }

  findByLatLng(latlng) {
    this.geocoder().geocode({
      location: latlng,
    }).then((response) => {
      if (response.results[0]) {
        const address = response.results.find((a) => a.types.find((b) => b === 'street_address'));

        this.searchTarget.value = response.results[0] && response.results[0].formatted_address || '';

        this.updateAddress(address);
      } else {
        window.alert('Posición desconocida');
        this.clearLocation();
      }
    }).catch((e) => console.log(`Geocoder failed due to: ${e}`));
  }

  marker() {
    if (this.markerObj === undefined) {
      this.markerObj = new google.maps.Marker({
        map: this.map(),
        anchorPoint: new google.maps.Point(0, 0),
        draggable: true,
      });

      let mapLocation = {
        lat: parseFloat(this.latitudeTarget.value),
        lng: parseFloat(this.longitudeTarget.value),
      };

      this.markerObj.setPosition(mapLocation);
      this.markerObj.setVisible(true);

      this.markerObj.addListener('dragend', (event) => {
        if (this.globalClickListenerController) {
          this.handleMarkerDraggedEvent(event);
        }
      });
    }
    return this.markerObj;
  }

  validLocation(address) {
    if (address && address.address_components) {
      for (let i = 0; i < address.address_components.length; i++) {
        let component = address.address_components[i];

        if (component.types && component.types.includes('country') && component.short_name !== 'UY') {
          return false;
        }
      }
    } else {
      return false;
    }

    let point = address.geometry.location;

    let invalidArea = this.geoXml().docs[0];
    let validArea = this.geoXml().docs[1];

    if (validArea && validArea.gpolygons) {
      for (let i = 0; i < validArea.gpolygons.length; i += 1) {
        if (!this.contains(validArea.gpolygons[i], point)) {
          return false;
        }
      }
    }

    if (invalidArea && invalidArea.gpolygons) {
      for (let i = 0; i < invalidArea.gpolygons.length; i += 1) {
        if (this.contains(invalidArea.gpolygons[i], point)) {
          return false;
        }
      }
    }

    return true;
  }

  autocomplete() {
    if (this.autocompleteObj === undefined) {
      this.autocompleteObj = new google.maps.places.Autocomplete(this.searchTarget, {
        componentRestrictions: { country: ['uy'] },
        fields: ['address_components', 'geometry', 'icon', 'name'],
        types: ['geocode', 'establishment']
      });
      // this.autocompleteObj.bindTo('bounds', this.map());
      this.autocompleteObj.addListener('place_changed', this.locationChanged.bind(this));
    }
    return this.autocompleteObj;
  }

  notifySelectOnChangeEvent(selectElement) {
    const event = new Event('change', { bubbles: true });
    selectElement.dispatchEvent(event);
  }

  waitUntilValue(value) {

    const optionObject = this.localityTarget.querySelector(`option[value='${value}']`)
    if (optionObject) {
      // this.localityTarget.selectedIndex = optionObject.index;
      this.localityTarget.selectedIndex = 1;
      this.notifySelectOnChangeEvent(this.localityTarget);

    } else {
      setTimeout(() => {
        this.waitUntilValue(value);
      }, 1000);
    }
  }

  async locationChanged() {
    let place = this.autocomplete().getPlace();

    // if (!place.geometry) {
    //   // User entered the name of a Place that was not suggested and
    //   // pressed the Enter key, or the Place Details request failed.
    //   window.alert(`No details available for input: '${place.name}'`);
    //   return;
    // }
    this.updateAddress(place);
  }

  clearLocation() {
    this.latitudeTarget.value = '';
    this.longitudeTarget.value = '';
    this.addressTarget.value = '';
    this.stateTarget.value = '';
    this.localityTarget.value = '';

    this.localityCopyTarget.value = '';
    this.stateCopyTarget.value = '';
  }

  updateAddress(address) {
    let address1 = '';
    // let postcode = '';
    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr

    // this.aptOrSuiteTarget.value = '';
    // this.cornerTarget.value = '';
    //
    if (!address || !address.geometry || !this.validLocation(address)) {
      if (address.name && address.name.match(/^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/)) {
        let latlngRaw = address.name.split(',');
        let lat = parseFloat(latlngRaw[0].trim());
        let lng = parseFloat(latlngRaw[1].trim());

        this.latitudeTarget.value = lat;
        this.longitudeTarget.value = lng;

        const latlng = {
          lat,
          lng,
        };

        this.findByLatLng(latlng);
      } else {
        window.alert('Posición inválida');
        this.clearLocation();
      }
    } else {
      this.enableMapInteraction();

      let hasValidStreetNumber = false;
      let locality = '';
      address.address_components.forEach((component) => {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
          case 'route': {
            address1 = `${component.short_name} ${address1}`.trim();
            break;
          }

          case 'street_number': {
            hasValidStreetNumber = true;
            address1 = `${component.long_name} ${address1}`.trim();
            break;
          }

          // case "postal_code": {
          //   postcode = `${component.long_name}${postcode}`;
          //   break;
          // }
          //
          // case "postal_code_suffix": {
          //   postcode = `${postcode}-${component.long_name}`;
          //   break;
          // }
          //
          case 'locality':
            locality = component.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            break;
          //
          case 'administrative_area_level_1': {
            this.stateTarget.value = component.short_name.replace('Departamento de ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            this.notifySelectOnChangeEvent(this.stateTarget);

            this.updateLocality(locality);

            break;
          }
          //
          // case "country":
          //   (document.querySelector("#country") as HTMLInputElement).value =
          //   component.long_name;
          //   break;
        }
      });

      this.map().fitBounds(address.geometry.viewport);
      this.map().setCenter(address.geometry.location);
      this.marker().setPosition(address.geometry.location);
      this.marker().setVisible(true);

      if(address.geometry.location_type) {
        this.latLngPrecisionTarget.value = address.geometry.location_type
      }else if(hasValidStreetNumber) {
        this.latLngPrecisionTarget.value = "ROOFTOP";
      }else{
        this.latLngPrecisionTarget.value = "APPROXIMATE";
      }

      this.latitudeTarget.value = address.geometry.location.lat();
      this.longitudeTarget.value = address.geometry.location.lng();

      this.addressTarget.value = address1;
    }
  }

  updateLocality(locality) {
    Swal.showLoading()

    let params = new URLSearchParams();
    params.append('state', this.stateTarget.value);

    this.localityCopyTarget.value = '';
    // params.append('target', this.localityTarget.id);

    // while (this.localityTarget.options.length > 0) {
    //   this.localityTarget.remove(0);
    // }

    this.localityTarget.innerHTML = '';
    this.localityTarget.appendChild(document.createElement("option"))

    get(this.urlValue, {query: params, headers: {'Accept': 'application/json'}})
        .then(response => response.response.json())
        .then(localities => {
          Swal.close();

          this.localityTarget.innerHTML = "";

          this.localityTarget.appendChild(document.createElement("option"));

          let index = 1;
          let localityFound = false;

          if(localities && !localities.error) {
            localities.forEach(current => {
              const option = document.createElement("option");
              option.value = current;
              option.text = current;

              this.localityTarget.appendChild(option)

              if (locality && (locality.toLowerCase() === current.toLowerCase())) {
                this.localityTarget.selectedIndex = index;
                localityFound = true;
                this.notifySelectOnChangeEvent(this.localityTarget);
              }

              index += 1;
            })

            if (locality && !localityFound) {
              Swal.fire({
                title: 'Error en la dirección',
                text: `No fue posible encontrar "${locality}" en "${this.stateTarget.value}"`,
                icon: 'error',
                confirmButtonText: 'Cerrar'
              });
            }
          }else{
            Swal.fire({
              title: 'Error en localidades',
              text: "La búsqueda de localidades no produjo resultados",
              icon: 'error',
              confirmButtonText: 'Cerrar'
            });
          }
        }).catch((e) => {
          Swal.close()

          Swal.fire({
            title: 'Error',
            text: e.message || 'Unknown code -1',
            icon: 'error',
            confirmButtonText: 'Cerrar'
          });
        })

  }

  preventSubmit(e) {
    if (e.key === 'Enter') { e.preventDefault(); }
  }
}
