import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'map',
    'latitude',
    'longitude',
  ];

  connect() {
    console.log('Initializing map view');
    if (typeof (google) !== 'undefined') {
      this.initializeMap();
    }
  }

  initializeMap() {
    this.map();
  }

  map() {

    if(!this.hasMapTarget) {
      return;
    }

    if (this.mapObj === undefined) {
      const URUGUAY_RESTRICTION = {
        south: -35.45694578574712,
        west: -62.13718940892936,
        north: -29.53125147341515,
        east: -50.51365425267936,
      };

      this.mapObj = new google.maps.Map(this.mapTarget, {
        componentRestrictions: { country: 'uy' },
        restriction: {
          latLngBounds: URUGUAY_RESTRICTION,
          strictBounds: true,
        },
        center: new google.maps.LatLng(
          this.latitudeTarget.value || -34.9040664,
          this.longitudeTarget.value || -56.1622566,
        ),
        zoom: 17,
      });

      this.mapObj.setOptions({
        draggable: true,
        zoomControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: false,
      });

      let markerObj = new google.maps.Marker({
        map: this.map(),
        anchorPoint: new google.maps.Point(0, 0),
        draggable: false,
      });

      let mapLocation = {
        lat: parseFloat(this.latitudeTarget.value),
        lng: parseFloat(this.longitudeTarget.value),
      };

      markerObj.setPosition(mapLocation);
      markerObj.setVisible(true);
    }
    return this.mapObj;
  }
}
