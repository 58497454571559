import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['numberInit', 'numberEnd', 'truckPlate', 'sendBtn', 'cancelBtn']
  static values = {
    apiTruckRegisterProduct: String,
  }

  initialize() {
    super.initialize();
    this.sendBtnTarget.onclick = this.onSendInfo.bind(this);
    // if()
    // document.getElementById(this.filterIdValue).setAttribute('hidden', '');
  }

  onSendInfo() {
    let formData = new FormData();

    formData.append('assign_truck[registration_plate]', this.truckPlateTarget.value);
    formData.append('assign_truck[init]', this.numberInitTarget.value);
    formData.append('assign_truck[end]', this.numberEndTarget.value);

    Rails.ajax({
      type: 'POST',
      accept: 'json',
      dataType: 'json',
      url: this.apiTruckRegisterProductValue,
      data: formData,
      success: this.onAssignedSearchResult.bind(this),
    });
  }

  onAssignedSearchResult(result) {
    if (result.status) {
      this.cancelBtnTarget.click();
    }
  }
}
