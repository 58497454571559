import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['truckInfo', 'input', 'submit']

  toggleSelection(event) {
    const clickedTruckInfo = event.currentTarget;

    if (clickedTruckInfo.classList.contains('text-gray-900')) {
      clickedTruckInfo.classList.replace('text-gray-900', 'text-gray-500');
      clickedTruckInfo.classList.remove('font-medium');
      this.submitTarget.setAttribute('disabled', 'disabled');
      this.inputTarget.value = '';
    } else {
      this.truckInfoTargets.forEach((truckInfo) => {
        truckInfo.classList.replace('text-gray-900', 'text-gray-500');
        truckInfo.classList.remove('font-medium');
      });
      clickedTruckInfo.classList.replace('text-gray-500', 'text-gray-900');
      clickedTruckInfo.classList.add('font-medium');
      this.inputTarget.value = clickedTruckInfo.dataset.driverId;
      this.submitTarget.removeAttribute('disabled');
    }
  }
}
