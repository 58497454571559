// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import '@hotwired/turbo-rails';
import 'bootstrap/js/src/modal'
import 'bootstrap/js/src/dropdown'
import '../channels';
import '../controllers';
import '../stylesheets/application.scss';

// https://github.com/anquinn/rails-tailwindcss-confirm-modal
import './rails-tailwindcss-confirm-modal';

import '@fortawesome/fontawesome-free/css/all';

import 'stylesheets/application';

import 'controllers';

Rails.start();
ActiveStorage.start();
