import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['']

    static values = {
      apiUrl: String,
      apiCheckStatusUrl: String,
      apiDownloadResultUrl: String,
    }

    timoutSchedule;

    connect() {
      super.connect();

      console.log('Connected...', this.apiUrlValue);

      fetch(`${this.apiUrlValue}?page=1&error_count=50`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }).then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
        .then((data) => {
          if (data.job_id) {
            console.log('Trabajando...');

            clearInterval(this.timoutSchedule);

            this.timoutSchedule = setInterval(() => {
              fetch(`${this.apiCheckStatusUrlValue}`, {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              }).then((response) => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              }).then((result) => {
                console.log(result);
              });
            }, 15000);
          } else if (data.status) {
            if (data.status === 'complete') {
              fetch(`${this.apiDownloadResultUrlValue}`, {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              }).then((response) => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              }).then((result) => {
                console.log(result);
              });
            } else {
              console.log('STATUS', data);
            }
          }
        })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
}
