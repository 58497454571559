import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['daysDropdown', 'hiddenDateInput']

  connect() {
    this.updateCurrentDate();
  }

  updateCurrentDate() {
    const days = this.daysDropdownTarget.options[this.daysDropdownTarget.selectedIndex].value;

    if (this.hasHiddenDateInputTarget) {
      this.updateInputDateValue(this.hiddenDateInputTarget, this.addDays(new Date(), days));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  formatDate(date) {
    let d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    let year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }

  // eslint-disable-next-line class-methods-use-this
  addDays(dateObj, numDays) {
    const daysInt = parseInt(numDays, 10);

    dateObj.setHours(0, 0, 0, 0);
    dateObj.setDate(dateObj.getDate() + daysInt);

    return dateObj;
  }

  updateInputDateValue(inputCmp, date) {
    // eslint-disable-next-line no-param-reassign
    inputCmp.value = this.formatDate(date);
  }
}
