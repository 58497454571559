import { Controller } from '@hotwired/stimulus';
import WebViewer from '@pdftron/pdfjs-express-viewer';

export default class extends Controller {
  static targets = [
    'pdfContainer',
    'loadingModal',
  ];

  static values = {
    pdfBinary: String,
    pdfClientKey: String,
  };

  // eslint-disable-next-line class-methods-use-this
  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i += 1) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'application/pdf' });
  }

  showLoading() {
    this.loadingModalTarget.classList.remove('hidden');
    this.loadingModalTarget.classList.add('show');
  }

  hiddeLoading() {
    this.loadingModalTarget.classList.remove('show');
    this.loadingModalTarget.classList.add('hidden');
  }

  validateEmails() {
    const emailInput = document.getElementById('emailInput');
    const errorMessage = document.getElementById('errorMessage');
    const emails = emailInput.value.split(',');

    errorMessage.textContent = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const email of emails) {
      const trimmedEmail = email.trim();
      if (!this.isValidEmail(trimmedEmail)) {
        errorMessage.textContent = 'Una o más direcciones de correo no son válidas.';
        emailInput.focus();
        return;
      }
    }
  }

  isValidEmail(email) {
    // Esta es una expresión regular simple para validar direcciones de correo.
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  connect() {
    const pdfData = this.pdfBinaryValue;

    this.showLoading();

    WebViewer({
      path: '/public', // point to where the files you copied are served from
      licenseKey: this.pdfClientKeyValue,
      // initialDoc: 'https://pdftron.s3.amazonaws.com/downloads/pl/PDFTRON_about.pdf', // path to your document
      // initialDoc: URL.createObjectURL(pdfFile), // path to your document
    }, this.pdfContainerTarget).then((instance) => {
      instance.UI.loadDocument(this.base64ToBlob(pdfData), { filename: 'order_preview.pdf' });

      const { documentViewer } = instance.Core;
      documentViewer.addEventListener('documentLoaded', () => {
        // perform document operations
        this.hiddeLoading();
      });
    });
  }
}
