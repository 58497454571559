import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import 'tui-time-picker/dist/tui-time-picker.css';
import 'tui-calendar/dist/tui-calendar.css';
import Calendar from 'tui-calendar';

export default class extends Controller {
  static values = {
    schedules: String,
  };

  connect() {
    this.initializeCalendar();

    this.loadSchedules();

    this.updatedCalendarSchedule();
    this.createCalendarSchedule();
    this.deleteCalendarSchedule();
  }

  loadSchedules() {
    if (this.schedulesValue !== '') {
      let schedulesArr = JSON.parse(this.schedulesValue);

      schedulesArr.forEach((schedule) => {
        this.calendar.createSchedules([
          {
            id: schedule.id,
            calendarId: schedule.id,
            title: schedule.title,
            category: 'time',
            dueDateClass: schedule.dueDateClass,
            location: schedule.location,
            start: schedule.start,
            end: schedule.end,
          },
        ]);
      });
    }
  }

  initializeCalendar() {
    this.calendar = new Calendar(document.getElementById('calendar'), {
      id: '1',
      name: 'My Calendar',
      defaultView: 'month',
      color: '#00a9ff',
      bgColor: '#00a9ff',
      dragBgColor: '#00a9ff',
      borderColor: 'red',

      milestone: true, // Can be also ['milestone', 'task']
      scheduleView: true, // Can be also ['allday', 'time']
      useCreationPopup: true,
      useDetailPopup: true,
      template: {

        popupDetailRepeat(schedule) {
          return `Repeat : ${schedule.recurrenceRule}`;
        },

        popupStateFree() {
          return 'Free';
        },
        milestone(schedule) {
          return `<span style="color:red;"><i class="fa fa-flag"></i> ${schedule.title}</span>`;
        },
        milestoneTitle() {
          return 'Milestone';
        },
        task(schedule) {
          return `&nbsp;&nbsp;#${schedule.title}`;
        },
        taskTitle() {
          return '<label><input type="checkbox" />Task</label>';
        },
        allday(schedule) {
          return `${schedule.title} <i class="fa fa-refresh"></i>`;
        },
        alldayTitle() {
          return 'All Day';
        },
        time(schedule) {
          return `${schedule.title} <i class="fa fa-refresh"></i>${schedule.start._date.toLocaleTimeString()}`;
        },
      },
      month: {
        daynames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        startDayOfWeek: 0,
        narrowWeekend: true,
      },
      week: {
        daynames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        startDayOfWeek: 0,
        narrowWeekend: true,
      },
    });
  }

  deleteCalendarSchedule() {
    let { calendar } = this;
    calendar.on('beforeDeleteSchedule', (event) => {
      let { schedule } = event;

      Rails.ajax({
        type: 'DELETE',
        url: `/booking_schedules/${schedule.id}`,
        success: () => {
          calendar.deleteSchedule(schedule.id, schedule.calendarId);
        },
      });
    });
  }

  updatedCalendarSchedule() {
    let { calendar } = this;
    calendar.on('beforeUpdateSchedule', (event) => {
      let { schedule } = event;
      let { changes } = event;

      let formUpdate = new FormData();
      if (changes.end) {
        formUpdate.append('booking_schedule[end]', changes.end._date);
      }
      if (changes.start) {
        formUpdate.append('booking_schedule[start]', changes.start._date);
      }
      if (changes.title) {
        formUpdate.append('booking_schedule[title]', changes.title);
      }
      if (changes.category) {
        formUpdate.append('booking_schedule[category]', changes.category);
      }

      if (changes.location) {
        formUpdate.append('booking_schedule[location]', changes.location);
      }

      if (changes.isAllDay !== undefined) {
        formUpdate.append('booking_schedule[start]', schedule.start._date.setHours(0, 0, 0, 0));
        formUpdate.append('booking_schedule[start]', schedule.end._date.setHours(23, 59, 59, 999));
      }

      Rails.ajax({
        type: 'PATCH',
        url: `/booking_schedules/${schedule.id}`,
        data: formUpdate,
        success: () => {
          calendar.updateSchedule(schedule.id, schedule.calendarId, changes);
        },
      });
    });
  }

  createCalendarSchedule() {
    let { calendar } = this;
    calendar.on('beforeCreateSchedule', (event) => {
      let { triggerEventName } = event;
      let schedule = {
        id: 1,
        calendarId: Math.floor(Math.random() * (2000000 - 1000000 + 1) + 1000000),
        title: event.title,
        category: 'time',
        location: event.location,
        start: event.start,
        end: event.end,
      };
      if (triggerEventName === 'click') {
        // open writing simple schedule popup
        // schedule = {...};
      } else if (triggerEventName === 'dblclick') {
        // open writing detail schedule popup
        // schedule = {...};
      }

      let formData = new FormData();

      formData.append('booking_schedule[title]', schedule.title);
      formData.append('booking_schedule[category]', schedule.category);
      formData.append('booking_schedule[start]', schedule.start._date);
      formData.append('booking_schedule[end]', schedule.end._date);
      formData.append('booking_schedule[location]', schedule.location);

      Rails.ajax({
        type: 'POST',
        accept: 'json',
        dataType: 'json',
        url: '/booking_schedules',
        data: formData,
        success: () => {
          calendar.createSchedules([schedule]);
        },
      });
    });
  }
}
