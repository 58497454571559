import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['backgroundMain', 'transparentDiv']

    static values = {
      landscapeImage: String,
      portraitImage: String,
      backgroundColor: String,
    }

    connect() {
      super.connect();

      if (this.backgroundColorValue) {
        this.backgroundMainTarget.style.backgroundColor = this.backgroundColorValue;
      }

      if ((this.portraitImageValue && this.landscapeImageValue) || this.backgroundColorValue) {
        this.transparentDivTarget.style.backgroundColor = '#ffffffb5';
      }

      if (this.portraitImageValue && this.landscapeImageValue) {
        this.updateBackground();
        window.addEventListener('resize', this.updateBackground.bind(this));
      }
    }

    updateBackground() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const isPortrait = height > width;
      const backgroundImageUrl = isPortrait ? this.portraitImageValue : this.landscapeImageValue;

      if (backgroundImageUrl) {
        this.backgroundMainTarget.style.backgroundImage = `url('${backgroundImageUrl}')`;
      }
    }

    disconnect() {
      super.disconnect();

      if (this.portraitImageValue && this.landscapeImageValue) {
        document.removeEventListener('resize', this.updateBackground.bind(this));
      }
    }
}
