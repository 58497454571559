import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // static targets = ['component', 'btn']
  static values = {
    filterId: String,
  }

  initialize() {
    super.initialize();

    // if()
    // document.getElementById(this.filterIdValue).setAttribute('hidden', '');
  }

  showMessage({ detail }) {
    console.log(detail);
  }

  toggleComponentVisibility() {
    document.getElementById(this.filterIdValue).toggleAttribute('hidden');
  }
}
