import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['userMenu', 'mobileMenu', 'mobileOpenMenuIcon', 'mobileCloseMenuIcon']

  toggleUserMenuVisibility() {
    this.userMenuTarget.classList.toggle('hidden');
  }

  toggleMobileMenuVisibility() {
    this.mobileMenuTarget.classList.toggle('hidden');
    this.mobileOpenMenuIconTarget.classList.toggle('hidden');
    this.mobileCloseMenuIconTarget.classList.toggle('hidden');
  }
}
