import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['select']
  static values = {
    url: String,
    param: String,
  }

  async updateOptions(event) {
    const selectedOptionValue = event.target.selectedOptions[0].value;

    if (selectedOptionValue === '') {
      this.selectTarget.setAttribute('disabled', 'disabled');
    }

    this.selectTarget.selectedIndex = null;

    let params = new URLSearchParams();
    params.append(this.paramValue, event.target.selectedOptions[0].value);
    params.append('target', this.selectTarget.id);

    const response = await get(this.urlValue, { query: params, responseKind: 'turbo-stream' });

    if (response.ok && selectedOptionValue !== '') {
      this.selectTarget.removeAttribute('disabled');
    }
  }
}
