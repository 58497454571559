import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['company_selector', 'phone_input', 'document_input']
  // static values = {
  //   locality_id: String,
  //   address_id: String,
  //   state_id: String,
  // }

  connect() {
    this.locality_selector = document.getElementById('[data-maps-target="locality"]');
    this.address_input = document.getElementById('[data-maps-target="address"]');
    this.state_selector = document.getElementById('[data-maps-target="state"]');

    // eslint-disable-next-line no-unused-vars
    this.companySelectorTarget.addEventListener('change', (_) => {
      // eslint-disable-next-line max-len
      const valSelected = this.companySelectorTarget.options[this.companySelectorTarget.selectedIndex];

      if (valSelected.dataset && (valSelected.dataset.rut || valSelected.dataset.phone)) {
        this.phoneInputValue.value = valSelected.dataset.phone;
        this.documentInputValue.value = valSelected.dataset.rut;

        this.address_input.value = valSelected.dataset.address;

        this.state_selector.value = valSelected.dataset.state;
        let changeEvent = new Event('change');
        this.state_selector.dispatchEvent(changeEvent);

        this.locality_selector.removeAttribute('disabled');

        this.waitUntilValue(this.capitalizeFirst(valSelected.dataset.locality.toLocaleLowerCase()));
      } else {
        this.phoneInputValue.value = '';
        this.documentInputValue.value = '';

        this.locality_selector.value = '';
        this.address_input = '';
        this.state_selector = '';
        // document.querySelector('[data-maps-target="address"]').value = '';
        // document.querySelector('[data-maps-target="state"]').value = '';
        // document.querySelector('[data-maps-target="locality"]').value = '';
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  capitalizeFirst(str) {
    const arr = str.split(' ');

    // loop through each element of the array and capitalize the first letter.

    for (let i = 0; i < arr.length; i += 1) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    // Join all the elements of the array back into a string
    // using a blankspace as a separator
    const str2 = arr.join(' ');

    return str2;
  }

  waitUntilValue(value) {
    if (document.querySelector(`#${this.localityIdValue} option[value='${value}']`)) {
      this.locality_selector.value = value;
    } else {
      setTimeout(() => {
        this.waitUntilValue(value);
      }, 1000);
    }
  }
}
